import { UIStreamCategories } from '@ikon-web/event-shared';
import { IkonController } from '@ikon-web/ikon-client';
import { PluginApi, Room, SpacePublic } from '@ikon-web/space-types';
import { IkonClientConfiguration } from '@ikon-web/utils';
import { Box } from '@mui/material';
import { debounce, isBoolean } from 'lodash-es';
import { CSSProperties, useCallback, useContext, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useWakeLock } from 'react-screen-wake-lock';
import { api } from '../../shared/api/api';
import { HAS_DEBUG_OVERLAY } from '../../shared/constants';
import { IkonContext } from '../../shared/context/ikon.context';
import { clearChatContainers, receiveContainer, receiveText, removeContainer, resetContainer } from '../../shared/data/container.slice';
import { AppDispatch } from '../../shared/data/store';
import { User } from '../../shared/domain/user';
import { useInteraction } from '../../shared/hooks/use-interaction';
import { usePageVisibility } from '../../shared/hooks/use-page-visiblity';
import { useRoomStack } from '../../shared/hooks/use-room-stack';
import { useWindowSize } from '../../shared/hooks/use-window-size';
import { iframeMessages } from '../../shared/utils/iframe.utils';
import { Chat } from '../chat/chat';
import { clearChat, resetChat, setBlockingAction, setChatRoom, setChatUserTyping } from '../chat/chat.slice';
import { DebugOverlay } from '../debug-overlay/debug-overlay';
import { openDebugOverlay } from '../debug-overlay/debug-overlay.slice';
import { closeSecondScreen, openSecondScreen, receiveSecondScreenContainer, removeSecondScreenContainer, resetSecondScreenContainer } from '../second-screen/second-screen-containers.slice';
import { setIkonConnected, setRoomUsers } from './room.slice';

const canvasSize: CSSProperties = {
  position: 'absolute',
  inset: '0',
  overflowX: 'hidden',
};

export function RoomIkon(props: {
  configuration: IkonClientConfiguration;
  space: SpacePublic;
  room: Room;
  user: User;
  initialPrompt?: string;
  videoEnabled: boolean;
  setVideoEnabled: (state: boolean) => void;
  onLeave: (error?: Error) => void;
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { setHasInteraction } = useInteraction();
  const windowSize = useWindowSize();
  const wakeLock = useWakeLock();
  const pageVisible = usePageVisibility();

  const roomStack = useRoomStack();
  const ikonContext = useContext(IkonContext);
  const [ikonController, setIkonController] = useState<IkonController>();
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const overlayCanvasRef = useRef<HTMLCanvasElement>(null);
  const sceneCanvasRef = useRef<HTMLCanvasElement>(null);
  const videoRef = useRef<HTMLVideoElement>(null);
  const containerRef = useRef(null);

  const [microphoneEnabled, setMicrophoneEnabled] = useState(false);
  const [showMessages] = useState(true);

  const [canvasWidth, setCanvasWidth] = useState(windowSize.width); //  - (props.space.layout === 'sidebar' ? 240 + 20 : 0); // sidenav + main content padding
  const [canvasHeight, setCanvasHeight] = useState(windowSize.height); // - (props.space.layout === 'sidebar' ? 48 + 64 + 10 : 0); // main nav + space nav + main content padding
  const [chatHeight, setChatHeight] = useState(windowSize.height - (props.space.layout === 'sidebar' ? 64 + 24 : 0));

  useEffect(() => {
    setCanvasWidth(windowSize.width);
    setCanvasHeight(windowSize.height);
    setChatHeight(windowSize.height - (props.space.layout === 'sidebar' ? 64 + 24 : 0));
  }, [props.space.layout, windowSize]);

  // const hasAudioRenderer = props.configuration.apis.includes(PluginApi.ClientAudioRenderer);
  const hasSceneRenderer = props.configuration.apis.includes(PluginApi.ClientSceneRenderer);
  const hasVideoRenderer = props.configuration.apis.includes(PluginApi.ClientVideoRenderer) || props.configuration.apis.includes(PluginApi.ClientBlobRenderer);
  const hasAudioRecorder = props.configuration.apis.includes(PluginApi.ClientAudioRecorder) || props.configuration.apis.includes(PluginApi.ClientAudioProcessor);
  const hasChat = props.configuration.apis.includes(PluginApi.Chat);
  const hasChatInput = props.configuration.apis.includes(PluginApi.ChatInput);
  const hasVisualRenderer = hasSceneRenderer || hasVideoRenderer || hasChat;

  const controlPosition: any = 'center'; //props.space.layout === 'sidebar' && hasVideoRenderer ? 'left' : 'center';

  useEffect(() => {
    if (pageVisible && wakeLock.isSupported && wakeLock.released) {
      console.log('[Meet] Requesting wake lock');
      wakeLock.request();
    }
    return () => {
      if (wakeLock.isSupported && wakeLock.released === false) {
        console.log('[Meet] Releasing wake lock');
        wakeLock.release();
      }
    };
  }, [pageVisible, wakeLock]);

  useLayoutEffect(() => {
    if (!props.configuration.url) return null;

    roomStack.pushRoom(props.room.code);
    dispatch(setChatRoom({ room: props.room }));

    if (canvasRef.current) {
      canvasRef.current.width = canvasWidth;
      canvasRef.current.height = canvasHeight;
    }

    const containersBuffer = [];
    const debounceContainerHistory = debounce(() => {
      for (const container of containersBuffer) {
        dispatch(receiveContainer(container));
        if (container.category === UIStreamCategories.SecondScreen) {
          dispatch(receiveSecondScreenContainer(container));
        }
      }
      containersBuffer.length = 0;
    }, 200);

    console.log(`[Meet] Starting Ikon Controller`);
    const controller = new IkonController(props.configuration, {
      user: { ...props.user, locale: navigator.language },
      overlayCanvas: overlayCanvasRef.current as HTMLCanvasElement,
      sceneCanvas: sceneCanvasRef.current as HTMLCanvasElement,
      videoCanvas: canvasRef.current as HTMLCanvasElement,
      videoElement: videoRef.current as HTMLVideoElement,
      uiContainerListener: (container) => {
        if (container.isHistory) {
          containersBuffer.push(container);
          debounceContainerHistory();
          return;
        }

        dispatch(receiveContainer(container));
        if (container.category === UIStreamCategories.SecondScreen) {
          dispatch(receiveSecondScreenContainer(container));
        }

        console.debug('[Meet] UI container received', container);
      },
      uiContainerRemoveListener: ({ id }) => {
        dispatch(removeContainer(id));
        dispatch(removeSecondScreenContainer(id));
      },
      uiTextListener: (data) => {
        dispatch(receiveText(data));
      },
      openUiListener: (data) => {
        if (data.category === UIStreamCategories.SecondScreen) {
          dispatch(openSecondScreen());
        }
      },
      closeUiListener: (data) => {
        if (data.category === UIStreamCategories.SecondScreen) {
          dispatch(closeSecondScreen());
        }
      },
      clearUiListener: (data) => {
        if (data.category === UIStreamCategories.Chat) {
          dispatch(clearChatContainers());
          dispatch(clearChat());
        }

        if (data.category === UIStreamCategories.SecondScreen) {
          dispatch(resetSecondScreenContainer());
        }
      },
      stateListener: (state) => {
        props.setVideoEnabled(Object.keys(state.VideoStreams).length > 0);
        const users = Object.values(state.Clients).map((userClient) => {
          const videoStream = Object.values(state.VideoStreams).find((stream) => stream.ClientSessionId === userClient.SessionId);
          return {
            id: userClient.UserId,
            sessionId: userClient.SessionId,
            isScreenSharing: !!videoStream,
          };
        });

        dispatch(setRoomUsers({ users }));
      },
      userActiveListener: ({ user, active }) => {
        dispatch(setChatUserTyping({ user, active }));
      },
      chatBlockingActionListener: (active: boolean) => {
        dispatch(setBlockingAction({ active }));
      },
      openRoomListener: (code: string, prompt?: string) => {
        setHasInteraction();
        navigate(`/rooms/${code}${prompt ? `?prompt=${prompt}` : ''}`, { state: { interaction: true } });
      },
      reloadRoomsListener: () => {
        dispatch(api.endpoints.getRooms.initiate({ space: props.space.id }, { forceRefetch: true }));
      },
      onLive: () => {
        if (props.initialPrompt) {
          // Try giving some time for agent to initialize
          setTimeout(() => controller.sendText(props.initialPrompt, true), 200);
        }
        dispatch(setIkonConnected());
        iframeMessages({ type: 'live' });
      },
      onClose: (error) => {
        console.log('[Meet] Ikon Controller closed');
        props.onLeave(error);

        iframeMessages({ type: 'close' });
      },
    });
    setIkonController(controller);
    ikonContext.setIkonController(controller);

    setMicrophoneEnabled(controller.microphoneEnabled);

    return () => {
      console.log('[Meet] Closing Ikon Controller');
      controller.close();

      ikonContext.setIkonController();
      dispatch(resetChat());
      dispatch(resetContainer());
      dispatch(resetSecondScreenContainer());

      console.log('[Meet] Closing Ikon Controller done');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.configuration?.url]);

  useEffect(() => {
    if (ikonController) {
      ikonController.resize(canvasWidth, canvasHeight);
    }
  }, [ikonController, canvasWidth, canvasHeight]);

  const sendText = useCallback((message, complete) => ikonController.sendText(message, complete), [ikonController]);
  const sendFile = useCallback((file) => ikonController.sendFile(file), [ikonController]);

  useHotkeys('alt+s', () => dispatch(openDebugOverlay()), [dispatch]);

  function toggleMicrophone(value?: boolean) {
    const enabled = isBoolean(value) ? value : !microphoneEnabled;
    setMicrophoneEnabled(enabled);
    ikonController.setMicrophoneEnabled(enabled);
  }

  return (
    <div>
      {hasVideoRenderer && (
        <>
          <video ref={videoRef} style={{ display: 'none' }}></video>
          <canvas
            ref={canvasRef}
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              height: '100dvh',
              width: '100%',
              zIndex: -100,
            }}
          ></canvas>
        </>
      )}
      <Box
        sx={{
          position: 'relative',
          width: '100%',
          height: `${chatHeight}px`,
          overflowX: 'hidden',
          overflowY: 'hidden',
        }}
        ref={containerRef}
      >
        {hasSceneRenderer && <canvas ref={sceneCanvasRef} width={canvasWidth} height={canvasHeight} style={{ ...canvasSize, display: props.videoEnabled ? 'none' : 'block' }}></canvas>}

        {hasVisualRenderer ? (
          <>
            <canvas ref={overlayCanvasRef} width={canvasWidth} height={canvasHeight} style={{ ...canvasSize, display: 'none' }}></canvas>

            {hasChat && showMessages ? (
              <Box
                sx={{
                  position: 'absolute',
                  top: '0px',
                  // height: '400px',
                  bottom: props.space.layout === 'sidebar' ? '0px' : '50px',
                  left: '0px',
                  right: '0px',
                  p: 0,
                }}
              >
                <Chat
                  sendMessage={sendText}
                  sendFile={sendFile}
                  setMicrophone={toggleMicrophone}
                  enableInput={hasChatInput}
                  enablePushToTalk={hasAudioRecorder}
                  controlPosition={controlPosition}
                ></Chat>
              </Box>
            ) : null}
          </>
        ) : null}
      </Box>
      {HAS_DEBUG_OVERLAY && <DebugOverlay />}
    </div>
  );
}
