import { UIElementLabels } from '@ikon-web/event-shared';
import { SxProps } from '@mui/material';
import Stack from '@mui/material/Stack';
import { UiContainerElements } from './ui-container-elements';

export function UiContainerColumn({ container, isCard, myMessage, disabled }: { container: any; isCard: boolean; myMessage: boolean; disabled?: boolean }) {
  const sx: SxProps = {};
  if (container.labels?.includes(UIElementLabels.SizeFullWidth)) {
    sx['width'] = '100%';
  }

  return (
    <Stack
      direction="column"
      spacing={container.gap || 0}
      useFlexGap
      sx={{
        ...sx,
        alignItems: 'left',
        justifyContent: 'left',
      }}
    >
      <UiContainerElements container={container} isCard={isCard} myMessage={myMessage} disabled={disabled} />
    </Stack>
  );
}
