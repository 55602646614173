import { encode, decode } from '@msgpack/msgpack';

export const MinimumHeaderLength = 27;

export enum UIStreamCategories {
    Chat = 'Chat',
    Header = 'Header',
    SecondScreen = 'SecondScreen',
    Preview = 'Preview',
    DebugOverlay = 'DebugOverlay',
}

export enum UIElementLabels {
    ChatMessage = 'chat-message',
    SizeSmall = 'size-small',
    SizeMedium = 'size-medium',
    SizeLarge = 'size-large',
    SizeFitContent = 'size-fit-content',
    SizeFullWidth = 'size-full-width',
    Blur = 'blur',
    Wrap = 'wrap',
    Disabled = 'disabled',
}


export interface MessageHeaders {
  length: number;
  opcode: Opcode;
  senderId: number;
  trackId: number;
  sequenceId: number;
  targetIdCount: number;
  payloadVersion: number;
  payloadType: PayloadType;
  flags: number;
  targetIds: number[];
}

export function readMessageHeaders(raw: ArrayBuffer): MessageHeaders {
  const dataView = new DataView(raw);
  const length = dataView.getUint32(0, true);
  const opcode = dataView.getUint32(4, true) as Opcode;
  const senderId = dataView.getUint32(8, true);
  const trackId = dataView.getUint32(12, true);
  const sequenceId = dataView.getUint32(16, true);
  const targetIdCount = dataView.getUint32(20, true);
  const payloadVersion = dataView.getUint8(24);
  const payloadType = dataView.getUint8(25) as PayloadType;
  const flags = dataView.getUint8(26);

  let offset = MinimumHeaderLength;
  const targetIds = [];

  for (let i = 0; i < targetIdCount; i++) {
    targetIds.push(dataView.getUint32(offset, true));
    offset += 4;
  }

  return {
    length,
    opcode,
    senderId,
    trackId,
    sequenceId,
    targetIdCount,
    payloadVersion,
    payloadType,
    flags,
    targetIds,
  };
}

function readMessagePayload(raw: ArrayBuffer): ArrayBuffer {
  const dataView = new DataView(raw);
  const targetIdCount = dataView.getUint32(20, true);
  return raw.slice(MinimumHeaderLength + targetIdCount * 4);
}

function createMessage(
  senderId: number,
  opcode: number,
  payloadBytes: Uint8Array,
  payloadVersion: number,
  payloadType: PayloadType,
  trackId: number,
  sequenceId: number,
  flags: number,
  targetIds: number[]
): Uint8Array {
  const headerSize = MinimumHeaderLength + targetIds.length * 4;
  const totalSize = headerSize + payloadBytes.length;

  const header = new Uint8Array(headerSize);
  const dataView = new DataView(header.buffer);

  dataView.setUint32(0, totalSize, true);
  dataView.setUint32(4, opcode, true);
  dataView.setUint32(8, senderId, true);
  dataView.setUint32(12, trackId, true);
  dataView.setUint32(16, sequenceId, true);
  dataView.setUint32(20, targetIds.length, true);
  dataView.setUint8(24, payloadVersion);
  dataView.setUint8(25, payloadType);
  dataView.setUint8(26, flags);
  
  let offset = MinimumHeaderLength;

  for (let i = 0; i < targetIds.length; i++) {
    dataView.setUint32(offset, targetIds[i], true);
    offset += 4;
  }

  const message = new Uint8Array(totalSize);
  message.set(header, 0);
  message.set(payloadBytes, headerSize);

  return message;
}



export enum AudioCodec {
  Unknown = 0,
  Opus = 1,
  Mp3 = 2,
}

export enum ContextType {
  Unknown = 0,
  Backend = 2,
  Server = 4,
  Plugin = 8,
  Browser = 16,
}

export enum EntrypointType {
  None = 0,
  WebSocket = 1,
  WebSocketProxy = 2,
  WebTransport = 4,
  WebTransportProxy = 8,
  Tcp = 16,
  TcpProxy = 32,
  Http = 64,
}

export enum FunctionKeyType {
  None = 0,
  String = 1,
  Int = 2,
}

export enum FunctionValueType {
  None = 0,
  Bool = 1,
  Int = 2,
  Float = 3,
  String = 4,
  Json = 5,
}

export enum LogFilter {
  None = 0,
  Critical = 32,
  Error = 48,
  Warning = 56,
  Info = 252,
  Debug = 254,
  Trace = 255,
}

export enum LogType {
  None = 0,
  Trace = 1,
  Debug = 2,
  Info = 4,
  Warning = 8,
  Error = 16,
  Critical = 32,
  Event = 64,
  Usage = 128,
}

export enum MessageFlag {
  None = 0,
  SendBackToSender = 1,
  Delayable = 2,
}

export enum Opcode {
  NONE = 0,
  CONSTANT_GROUP_BITS = 15,
  CONSTANT_GROUP_OFFSET = 16,
  GROUP_CORE = 65536,
  CORE_AUTH_RESPONSE = 65537,
  CORE_AUTH_TICKET = 65538,
  CORE_GLOBAL_STATE = 65539,
  CORE_ON_SERVER_STATUS_PING = 65540,
  CORE_ON_USER_JOINED = 65541,
  CORE_ON_USER_LEFT = 65542,
  CORE_ON_CLIENT_JOINED = 65543,
  CORE_ON_CLIENT_LEFT = 65544,
  CORE_ON_SERVER_STARTED = 65545,
  CORE_ON_SERVER_STOPPED = 65546,
  CORE_ON_SERVER_STOPPING = 65547,
  CORE_ON_CLIENT_READY = 65548,
  CORE_CLIENT_READY = 65549,
  CORE_SERVER_INIT = 65550,
  CORE_ON_PLUGIN_RELOADED = 65551,
  CORE_SERVER_START = 65552,
  CORE_SERVER_STOP = 65553,
  CORE_ON_HOSTED_SERVER_EXIT = 65554,
  GROUP_KEEPALIVE = 131072,
  KEEPALIVE_REQUEST = 131073,
  KEEPALIVE_RESPONSE = 131074,
  GROUP_EVENTS = 262144,
  EVENTS_PROFILE_UPDATE = 262145,
  EVENTS_CHANNEL_COMPLETE = 262146,
  GROUP_ANALYTICS = 524288,
  ANALYTICS_LOGS = 524289,
  ANALYTICS_EVENTS = 524290,
  ANALYTICS_USAGES = 524291,
  ANALYTICS_USAGE = 524292,
  ANALYTICS_SPECIAL_LOG = 524293,
  ANALYTICS_PROCESSING_UPDATE = 524294,
  GROUP_ACTIONS = 1048576,
  ACTION_CALL = 1048577,
  ACTION_ACTIVE = 1048578,
  ACTION_TEXT_OUTPUT = 1048579,
  ACTION_TEXT_OUTPUT_DELTA = 1048580,
  ACTION_TEXT_OUTPUT_DELTA_FULL = 1048581,
  ACTION_SET_STATE = 1048582,
  ACTION_TAP = 1048583,
  ACTION_PAN = 1048584,
  ACTION_ZOOM = 1048585,
  ACTION_FILE_UPLOAD_BEGIN = 1048586,
  ACTION_FILE_UPLOAD_DATA = 1048587,
  ACTION_FILE_UPLOAD_ACK = 1048588,
  ACTION_FILE_UPLOAD_END = 1048589,
  ACTION_FILE_UPLOAD_RESULT = 1048590,
  ACTION_OPEN_CHANNEL = 1048591,
  ACTION_OPEN_EXTERNAL_URL = 1048592,
  ACTION_UI_OPEN_VIEW = 1048593,
  ACTION_UI_CLOSE_VIEW = 1048594,
  ACTION_UI_BLOCKING_BEGIN = 1048595,
  ACTION_UI_BLOCKING_END = 1048596,
  ACTION_UI_UPDATE_TEXT_DELTA = 1048597,
  ACTION_UI_DELETE_CONTAINER = 1048598,
  ACTION_UPDATE_GFX_SHADER = 1048599,
  ACTION_FUNCTION_REGISTER = 1048600,
  ACTION_FUNCTION_CALL = 1048601,
  ACTION_FUNCTION_CALL_RESULT = 1048602,
  ACTION_GENERATE_ANSWER = 1048603,
  ACTION_REGENERATE_ANSWER = 1048604,
  ACTION_CLEAR_MESSAGE_HISTORY = 1048605,
  ACTION_CLEAR_STATE = 1048606,
  ACTION_RELOAD_CHANNELS = 1048607,
  ACTION_RELOAD_PROFILE = 1048608,
  ACTION_CLASSIFICATION_RESULT = 1048609,
  ACTION_AUDIO_STOP = 1048610,
  ACTION_CALL_TEXT = 1048611,
  GROUP_UI = 2097152,
  UI_STREAM_BEGIN = 2097153,
  UI_STREAM_END = 2097154,
  UI_CONTAINER_BEGIN = 2097155,
  UI_CONTAINER_END = 2097156,
  UI_SECTION_BEGIN = 2097157,
  UI_SECTION_END = 2097158,
  UI_LIST_BEGIN = 2097159,
  UI_LIST_ITEM = 2097160,
  UI_LIST_END = 2097161,
  UI_TEXT = 2097162,
  UI_HEADER = 2097163,
  UI_SEPARATOR = 2097164,
  UI_BUTTON = 2097165,
  UI_ICON_BUTTON = 2097166,
  UI_IMAGE = 2097167,
  UI_FILE = 2097168,
  UI_BADGE = 2097169,
  UI_CONTENT_LINK = 2097170,
  UI_MAP = 2097171,
  UI_VEGA_CHART = 2097172,
  UI_ICON = 2097173,
  UI_FILE_UPLOAD_SECTION_BEGIN = 2097174,
  UI_FILE_UPLOAD_SECTION_END = 2097175,
  UI_MATERIAL_SYMBOL = 2097176,
  UI_BUTTON_BEGIN = 2097177,
  UI_BUTTON_END = 2097178,
  UI_CONTAINER_DELETE = 2097179,
  UI_INPUT_TEXT = 2097180,
  GROUP_AUDIO = 4194304,
  AUDIO_STREAM_BEGIN = 4194305,
  AUDIO_STREAM_END = 4194306,
  AUDIO_FRAME = 4194307,
  AUDIO_FRAME_VOLUME = 4194308,
  GROUP_VIDEO = 8388608,
  VIDEO_STREAM_BEGIN = 8388609,
  VIDEO_STREAM_END = 8388610,
  VIDEO_FRAME = 8388611,
  VIDEO_REQUEST_IDR_FRAME = 8388612,
  VIDEO_INVALIDATE_FRAME = 8388613,
  GROUP_TRACKING = 16777216,
  TRACKING_STREAM_BEGIN = 16777217,
  TRACKING_STREAM_END = 16777218,
  TRACKING_FRAME = 16777219,
  GROUP_SCENE = 33554432,
  SCENE_MESH = 33554433,
  SCENE_ARRAY = 33554434,
  GROUP_MISC = 1073741824,
  GROUP_COMMON = 1077870592,
  GROUP_ALL = 1140785152,
  CONSTANT_GROUP_MASK = 2147418112,
}

export enum PayloadType {
  Unknown = 0,
  MessagePack = 1,
  MemoryPack = 2,
  Json = 4,
  All = 7,
}

export enum ProtocolVersion {
  Version = 1,
}

export enum ServerStatus {
  Unknown = 0,
  Starting = 1,
  Running = 2,
  Stopping = 3,
  Stopped = 4,
}

export enum TrackingType {
  Face = 1,
  Hands = 2,
  Pose = 4,
  All = 7,
}

export enum UIBadgeVariant {
  Filled = 0,
  Outlined = 1,
}

export enum UIButtonVariant {
  Outlined = 0,
  Contained = 1,
  Text = 2,
}

export enum UIColor {
  Default = 0,
  Primary = 1,
  Secondary = 2,
  Error = 3,
  Warning = 4,
  Info = 5,
  Success = 6,
}

export enum UIContentLinkType {
  Unknown = 0,
  Youtube = 1,
}

export enum UIHeaderLevel {
  Default = 0,
  Large = 1,
  Medium = 2,
  Normal = 3,
  Small = 4,
}

export enum UIIconType {
  None = 0,
  Close = 1,
  Download = 2,
  Delete = 3,
  PinDrop = 4,
  Favorite = 5,
  FavoriteBorder = 6,
  AddCircle = 7,
  AddCircleOutline = 8,
  StarOutline = 9,
  Document = 10,
  GenderMale = 11,
  GenderFemale = 12,
  Upload = 13,
  GenderOther = 14,
}

export enum UIInputVariant {
  Outlined = 0,
  Filled = 1,
}

export enum UIListType {
  Default = 0,
  Unordered = 1,
  Ordered = 2,
  Definition = 3,
}

export enum UISectionType {
  Default = 0,
  Column = 1,
  Row = 2,
  Card = 3,
  Right = 4,
  Carousel = 5,
}

export enum UITextType {
  Normal = 0,
  Caption = 1,
  Strong = 2,
  CaptionSmall = 3,
}

export enum UserType {
  Unknown = 0,
  Machine = 1,
  Human = 2,
}

export enum VideoCodec {
  Unknown = 0,
  H264 = 1,
  Hevc = 2,
  Vp8 = 3,
}


export interface ActionActive {
}

export function readActionActive(raw: ArrayBuffer): ActionActive {
  return decode(readMessagePayload(raw)) as ActionActive;
}

export function createActionActive({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: ActionActive;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  return createMessage(senderId, 1048578, encode(instance, {forceFloat32: true }), 2, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface ActionAudioStop {
  AudioStreamId: string;
  FadeoutTimeInSec: number;
}

export function readActionAudioStop(raw: ArrayBuffer): ActionAudioStop {
  return decode(readMessagePayload(raw)) as ActionAudioStop;
}

export function createActionAudioStop({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: ActionAudioStop;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  return createMessage(senderId, 1048610, encode(instance, {forceFloat32: true }), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface ActionCall {
  ActionId: string;
}

export function readActionCall(raw: ArrayBuffer): ActionCall {
  return decode(readMessagePayload(raw)) as ActionCall;
}

export function createActionCall({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: ActionCall;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  return createMessage(senderId, 1048577, encode(instance, {forceFloat32: true }), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface ActionCallText {
  ActionId: string;
  Text: string;
}

export function readActionCallText(raw: ArrayBuffer): ActionCallText {
  return decode(readMessagePayload(raw)) as ActionCallText;
}

export function createActionCallText({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: ActionCallText;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  return createMessage(senderId, 1048611, encode(instance, {forceFloat32: true }), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface ActionClassificationDetail {
}

export interface ActionClassificationResult {
}

export function readActionClassificationResult(raw: ArrayBuffer): ActionClassificationResult {
  return decode(readMessagePayload(raw)) as ActionClassificationResult;
}

export function createActionClassificationResult({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: ActionClassificationResult;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  return createMessage(senderId, 1048609, encode(instance, {forceFloat32: true }), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface ActionClearMessageHistory {
}

export function readActionClearMessageHistory(raw: ArrayBuffer): ActionClearMessageHistory {
  return decode(readMessagePayload(raw)) as ActionClearMessageHistory;
}

export function createActionClearMessageHistory({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: ActionClearMessageHistory;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  return createMessage(senderId, 1048605, encode(instance, {forceFloat32: true }), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface ActionClearState {
}

export function readActionClearState(raw: ArrayBuffer): ActionClearState {
  return decode(readMessagePayload(raw)) as ActionClearState;
}

export function createActionClearState({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: ActionClearState;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  return createMessage(senderId, 1048606, encode(instance, {forceFloat32: true }), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface ActionFileUploadAck {
  ActionId: string;
  SequenceId: number;
}

export function readActionFileUploadAck(raw: ArrayBuffer): ActionFileUploadAck {
  return decode(readMessagePayload(raw)) as ActionFileUploadAck;
}

export function createActionFileUploadAck({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: ActionFileUploadAck;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  return createMessage(senderId, 1048588, encode(instance, {forceFloat32: true }), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface ActionFileUploadBegin {
  ActionId: string;
  FileName: string;
  Mime: string;
  ByteCount: number;
  CheckHash: boolean;
  Hash: string;
}

export function readActionFileUploadBegin(raw: ArrayBuffer): ActionFileUploadBegin {
  return decode(readMessagePayload(raw)) as ActionFileUploadBegin;
}

export function createActionFileUploadBegin({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: ActionFileUploadBegin;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  return createMessage(senderId, 1048586, encode(instance, {forceFloat32: true }), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface ActionFileUploadData {
  ActionId: string;
  Data: Uint8Array;
  SequenceId: number;
}

export function readActionFileUploadData(raw: ArrayBuffer): ActionFileUploadData {
  return decode(readMessagePayload(raw)) as ActionFileUploadData;
}

export function createActionFileUploadData({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: ActionFileUploadData;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  return createMessage(senderId, 1048587, encode(instance, {forceFloat32: true }), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface ActionFileUploadEnd {
  ActionId: string;
}

export function readActionFileUploadEnd(raw: ArrayBuffer): ActionFileUploadEnd {
  return decode(readMessagePayload(raw)) as ActionFileUploadEnd;
}

export function createActionFileUploadEnd({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: ActionFileUploadEnd;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  return createMessage(senderId, 1048589, encode(instance, {forceFloat32: true }), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface ActionFileUploadResult {
  ActionId: string;
  IsSuccess: boolean;
  ErrorMessage: string;
}

export function readActionFileUploadResult(raw: ArrayBuffer): ActionFileUploadResult {
  return decode(readMessagePayload(raw)) as ActionFileUploadResult;
}

export function createActionFileUploadResult({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: ActionFileUploadResult;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  return createMessage(senderId, 1048590, encode(instance, {forceFloat32: true }), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface ActionFunctionCall {
  FunctionName: string;
  CallId: string;
  CallArgumentsJson: string[];
}

export function readActionFunctionCall(raw: ArrayBuffer): ActionFunctionCall {
  return decode(readMessagePayload(raw)) as ActionFunctionCall;
}

export function createActionFunctionCall({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: ActionFunctionCall;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  return createMessage(senderId, 1048601, encode(instance, {forceFloat32: true }), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface ActionFunctionCallResult {
  CallId: string;
  ResultJson: string;
  ResultValueType: ProtocolValueType;
}

export function readActionFunctionCallResult(raw: ArrayBuffer): ActionFunctionCallResult {
  return decode(readMessagePayload(raw)) as ActionFunctionCallResult;
}

export function createActionFunctionCallResult({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: ActionFunctionCallResult;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  return createMessage(senderId, 1048602, encode(instance, {forceFloat32: true }), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface ActionFunctionRegister {
  FunctionName: string;
  Description: string;
  InlineCall: boolean;
  CallOnlyOnce: boolean;
  ReturnValueType: ProtocolValueType;
  Parameters: FunctionParameter[];
}

export function readActionFunctionRegister(raw: ArrayBuffer): ActionFunctionRegister {
  return decode(readMessagePayload(raw)) as ActionFunctionRegister;
}

export function createActionFunctionRegister({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: ActionFunctionRegister;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  return createMessage(senderId, 1048600, encode(instance, {forceFloat32: true }), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface ActionGenerateAnswer {
}

export function readActionGenerateAnswer(raw: ArrayBuffer): ActionGenerateAnswer {
  return decode(readMessagePayload(raw)) as ActionGenerateAnswer;
}

export function createActionGenerateAnswer({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: ActionGenerateAnswer;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  return createMessage(senderId, 1048603, encode(instance, {forceFloat32: true }), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface ActionOpenChannel {
  ChannelCode: string;
  Prompt: string;
}

export function readActionOpenChannel(raw: ArrayBuffer): ActionOpenChannel {
  return decode(readMessagePayload(raw)) as ActionOpenChannel;
}

export function createActionOpenChannel({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: ActionOpenChannel;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  return createMessage(senderId, 1048591, encode(instance, {forceFloat32: true }), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface ActionOpenExternalUrl {
  Name: string;
  Url: string;
}

export function readActionOpenExternalUrl(raw: ArrayBuffer): ActionOpenExternalUrl {
  return decode(readMessagePayload(raw)) as ActionOpenExternalUrl;
}

export function createActionOpenExternalUrl({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: ActionOpenExternalUrl;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  return createMessage(senderId, 1048592, encode(instance, {forceFloat32: true }), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface ActionPan {
  Location: Coordinate2D;
  Delta: Coordinate2D;
}

export function readActionPan(raw: ArrayBuffer): ActionPan {
  return decode(readMessagePayload(raw)) as ActionPan;
}

export function createActionPan({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: ActionPan;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  return createMessage(senderId, 1048584, encode(instance, {forceFloat32: true }), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface ActionRegenerateAnswer {
}

export function readActionRegenerateAnswer(raw: ArrayBuffer): ActionRegenerateAnswer {
  return decode(readMessagePayload(raw)) as ActionRegenerateAnswer;
}

export function createActionRegenerateAnswer({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: ActionRegenerateAnswer;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  return createMessage(senderId, 1048604, encode(instance, {forceFloat32: true }), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface ActionReloadChannels {
}

export function readActionReloadChannels(raw: ArrayBuffer): ActionReloadChannels {
  return decode(readMessagePayload(raw)) as ActionReloadChannels;
}

export function createActionReloadChannels({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: ActionReloadChannels;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  return createMessage(senderId, 1048607, encode(instance, {forceFloat32: true }), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface ActionReloadProfile {
  ProfileId: string;
  UserId: string;
}

export function readActionReloadProfile(raw: ArrayBuffer): ActionReloadProfile {
  return decode(readMessagePayload(raw)) as ActionReloadProfile;
}

export function createActionReloadProfile({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: ActionReloadProfile;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  return createMessage(senderId, 1048608, encode(instance, {forceFloat32: true }), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface ActionSetState {
  Key: string;
  ValueJson: string;
  ValueType: ProtocolValueType;
}

export function readActionSetState(raw: ArrayBuffer): ActionSetState {
  return decode(readMessagePayload(raw)) as ActionSetState;
}

export function createActionSetState({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: ActionSetState;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  return createMessage(senderId, 1048582, encode(instance, {forceFloat32: true }), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface ActionTap {
  Location: Coordinate2D;
}

export function readActionTap(raw: ArrayBuffer): ActionTap {
  return decode(readMessagePayload(raw)) as ActionTap;
}

export function createActionTap({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: ActionTap;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  return createMessage(senderId, 1048583, encode(instance, {forceFloat32: true }), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface ActionTextOutput {
  UserId: string;
  Text: string;
  GenerateChatMessage: boolean;
  CreatedAt: string;
}

export function readActionTextOutput(raw: ArrayBuffer): ActionTextOutput {
  return decode(readMessagePayload(raw)) as ActionTextOutput;
}

export function createActionTextOutput({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: ActionTextOutput;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  return createMessage(senderId, 1048579, encode(instance, {forceFloat32: true }), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface ActionTextOutputDelta {
  Delta: string;
}

export function readActionTextOutputDelta(raw: ArrayBuffer): ActionTextOutputDelta {
  return decode(readMessagePayload(raw)) as ActionTextOutputDelta;
}

export function createActionTextOutputDelta({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: ActionTextOutputDelta;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  return createMessage(senderId, 1048580, encode(instance, {forceFloat32: true }), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface ActionTextOutputDeltaFull {
  Full: string;
}

export function readActionTextOutputDeltaFull(raw: ArrayBuffer): ActionTextOutputDeltaFull {
  return decode(readMessagePayload(raw)) as ActionTextOutputDeltaFull;
}

export function createActionTextOutputDeltaFull({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: ActionTextOutputDeltaFull;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  return createMessage(senderId, 1048581, encode(instance, {forceFloat32: true }), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface ActionUIBlockingBegin {
}

export function readActionUIBlockingBegin(raw: ArrayBuffer): ActionUIBlockingBegin {
  return decode(readMessagePayload(raw)) as ActionUIBlockingBegin;
}

export function createActionUIBlockingBegin({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: ActionUIBlockingBegin;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  return createMessage(senderId, 1048595, encode(instance, {forceFloat32: true }), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface ActionUIBlockingEnd {
}

export function readActionUIBlockingEnd(raw: ArrayBuffer): ActionUIBlockingEnd {
  return decode(readMessagePayload(raw)) as ActionUIBlockingEnd;
}

export function createActionUIBlockingEnd({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: ActionUIBlockingEnd;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  return createMessage(senderId, 1048596, encode(instance, {forceFloat32: true }), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface ActionUICloseView {
}

export function readActionUICloseView(raw: ArrayBuffer): ActionUICloseView {
  return decode(readMessagePayload(raw)) as ActionUICloseView;
}

export function createActionUICloseView({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: ActionUICloseView;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  return createMessage(senderId, 1048594, encode(instance, {forceFloat32: true }), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface ActionUIDeleteContainer {
  ContainerId: string;
}

export function readActionUIDeleteContainer(raw: ArrayBuffer): ActionUIDeleteContainer {
  return decode(readMessagePayload(raw)) as ActionUIDeleteContainer;
}

export function createActionUIDeleteContainer({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: ActionUIDeleteContainer;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  return createMessage(senderId, 1048598, encode(instance, {forceFloat32: true }), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface ActionUIOpenView {
}

export function readActionUIOpenView(raw: ArrayBuffer): ActionUIOpenView {
  return decode(readMessagePayload(raw)) as ActionUIOpenView;
}

export function createActionUIOpenView({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: ActionUIOpenView;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  return createMessage(senderId, 1048593, encode(instance, {forceFloat32: true }), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface ActionUIUpdateTextDelta {
  ContainerId: string;
  ElementId: number;
  Delta: string;
}

export function readActionUIUpdateTextDelta(raw: ArrayBuffer): ActionUIUpdateTextDelta {
  const instanceAsArray = decode(readMessagePayload(raw)) as any[];

  const instance: ActionUIUpdateTextDelta = {
    ContainerId: instanceAsArray[0],
    ElementId: instanceAsArray[1],
    Delta: instanceAsArray[2],
  };

  return instance;
}

export function createActionUIUpdateTextDelta({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: ActionUIUpdateTextDelta;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  const instanceAsArray: any[] = [
    instance.ContainerId,
    instance.ElementId,
    instance.Delta,
  ];

  return createMessage(senderId, 1048597, encode(instanceAsArray, {forceFloat32: true }), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface ActionUpdateGfxShader {
  Name: string;
  Fps: number;
  Content: string;
  ContentHash: string;
}

export function readActionUpdateGfxShader(raw: ArrayBuffer): ActionUpdateGfxShader {
  return decode(readMessagePayload(raw)) as ActionUpdateGfxShader;
}

export function createActionUpdateGfxShader({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: ActionUpdateGfxShader;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  return createMessage(senderId, 1048599, encode(instance, {forceFloat32: true }), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface ActionZoom {
  StartScale: number;
  CurrentScale: number;
}

export function readActionZoom(raw: ArrayBuffer): ActionZoom {
  return decode(readMessagePayload(raw)) as ActionZoom;
}

export function createActionZoom({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: ActionZoom;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  return createMessage(senderId, 1048585, encode(instance, {forceFloat32: true }), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface AnalyticsEvents {
  Events: AnalyticsEventsItem[];
}

export function readAnalyticsEvents(raw: ArrayBuffer): AnalyticsEvents {
  return decode(readMessagePayload(raw)) as AnalyticsEvents;
}

export function createAnalyticsEvents({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: AnalyticsEvents;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  return createMessage(senderId, 524290, encode(instance, {forceFloat32: true }), 2, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface AnalyticsEventsItem {
  Time: string;
  EventName: string;
  Message: string;
  Parameters: string;
}

export interface AnalyticsLogs {
  Logs: AnalyticsLogsItem[];
}

export function readAnalyticsLogs(raw: ArrayBuffer): AnalyticsLogs {
  return decode(readMessagePayload(raw)) as AnalyticsLogs;
}

export function createAnalyticsLogs({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: AnalyticsLogs;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  return createMessage(senderId, 524289, encode(instance, {forceFloat32: true }), 4, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface AnalyticsLogsItem {
  Time: string;
  Type: number;
  Message: string;
  Parameters: string;
}

export interface AnalyticsProcessingUpdate {
  ProcessingId: string;
  TotalRuns: number;
  TotalItems: number;
  TotalRetries: number;
  TotalFailures: number;
  StartedAt: string;
  ElapsedSeconds: number;
  Usages: Record<string, number>;
  RunsRemaining: number;
  ItemsRemaining: number;
  EstimatedTimeLeftSecondsRuns: number;
  EstimatedTimeLeftSecondsItems: number;
}

export function readAnalyticsProcessingUpdate(raw: ArrayBuffer): AnalyticsProcessingUpdate {
  return decode(readMessagePayload(raw)) as AnalyticsProcessingUpdate;
}

export function createAnalyticsProcessingUpdate({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: AnalyticsProcessingUpdate;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  return createMessage(senderId, 524294, encode(instance, {forceFloat32: true }), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface AnalyticsSpecialLog {
  Title: string;
  Message: string;
}

export function readAnalyticsSpecialLog(raw: ArrayBuffer): AnalyticsSpecialLog {
  return decode(readMessagePayload(raw)) as AnalyticsSpecialLog;
}

export function createAnalyticsSpecialLog({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: AnalyticsSpecialLog;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  return createMessage(senderId, 524293, encode(instance, {forceFloat32: true }), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface AnalyticsUsage {
  UsageName: string;
  Usage: number;
}

export function readAnalyticsUsage(raw: ArrayBuffer): AnalyticsUsage {
  return decode(readMessagePayload(raw)) as AnalyticsUsage;
}

export function createAnalyticsUsage({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: AnalyticsUsage;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  return createMessage(senderId, 524292, encode(instance, {forceFloat32: true }), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface AnalyticsUsages {
  Usages: AnalyticsUsagesItem[];
}

export function readAnalyticsUsages(raw: ArrayBuffer): AnalyticsUsages {
  return decode(readMessagePayload(raw)) as AnalyticsUsages;
}

export function createAnalyticsUsages({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: AnalyticsUsages;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  return createMessage(senderId, 524291, encode(instance, {forceFloat32: true }), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface AnalyticsUsagesItem {
  Time: string;
  EventName: string;
  Usage: number;
  Parameters: string;
}

export interface AudioFrame {
  Data: Uint8Array;
  IsKey: boolean;
  IsLast: boolean;
  TimestampInUs: number;
  DurationInUs: number;
  IsFirst: boolean;
  TotalDurationInUs: number;
}

export function readAudioFrame(raw: ArrayBuffer): AudioFrame {
  const instanceAsArray = decode(readMessagePayload(raw)) as any[];

  const instance: AudioFrame = {
    Data: instanceAsArray[0],
    IsKey: instanceAsArray[1],
    IsLast: instanceAsArray[2],
    TimestampInUs: instanceAsArray[3],
    DurationInUs: instanceAsArray[4],
    IsFirst: instanceAsArray[5],
    TotalDurationInUs: instanceAsArray[6],
  };

  return instance;
}

export function createAudioFrame({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: AudioFrame;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  const instanceAsArray: any[] = [
    instance.Data,
    instance.IsKey,
    instance.IsLast,
    instance.TimestampInUs,
    instance.DurationInUs,
    instance.IsFirst,
    instance.TotalDurationInUs,
  ];

  return createMessage(senderId, 4194307, encode(instanceAsArray, {forceFloat32: true }), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface AudioFrameVolume {
  Volume: number;
  Count: number;
}

export function readAudioFrameVolume(raw: ArrayBuffer): AudioFrameVolume {
  const instanceAsArray = decode(readMessagePayload(raw)) as any[];

  const instance: AudioFrameVolume = {
    Volume: instanceAsArray[0],
    Count: instanceAsArray[1],
  };

  return instance;
}

export function createAudioFrameVolume({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: AudioFrameVolume;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  const instanceAsArray: any[] = [
    instance.Volume,
    instance.Count,
  ];

  return createMessage(senderId, 4194308, encode(instanceAsArray, {forceFloat32: true }), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface AudioStreamBegin {
  Description: string;
  Codec: AudioCodec;
  SampleRate: number;
  Channels: number;
  BitDepth: number;
}

export function readAudioStreamBegin(raw: ArrayBuffer): AudioStreamBegin {
  return decode(readMessagePayload(raw)) as AudioStreamBegin;
}

export function createAudioStreamBegin({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: AudioStreamBegin;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  return createMessage(senderId, 4194305, encode(instance, {forceFloat32: true }), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface AudioStreamEnd {
}

export function readAudioStreamEnd(raw: ArrayBuffer): AudioStreamEnd {
  return decode(readMessagePayload(raw)) as AudioStreamEnd;
}

export function createAudioStreamEnd({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: AudioStreamEnd;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  return createMessage(senderId, 4194306, encode(instance, {forceFloat32: true }), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface AudioStreamState {
  StreamId: string;
  ClientSessionId: number;
  TrackId: number;
  Info: AudioStreamBegin;
}

export interface AuthResponse {
  ClientContext: Context;
  ServerContext: Context;
  CertHash: string;
  Entrypoints: Entrypoint[];
  FeatureFlags: Record<string, boolean>;
  SpaceId: string;
  RoomId: string;
}

export function readAuthResponse(raw: ArrayBuffer): AuthResponse {
  return decode(readMessagePayload(raw)) as AuthResponse;
}

export function createAuthResponse({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: AuthResponse;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  return createMessage(senderId, 65537, encode(instance, {forceFloat32: true }), 5, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface AuthTicket {
  Host: string;
  HttpsPort: number;
  TcpPort: number;
  Secret: string;
  OpcodeGroupsFromServer: Opcode;
  OpcodeGroupsToServer: Opcode;
  ClientContext: Context;
}

export function readAuthTicket(raw: ArrayBuffer): AuthTicket {
  return decode(readMessagePayload(raw)) as AuthTicket;
}

export function createAuthTicket({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: AuthTicket;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  return createMessage(senderId, 65538, encode(instance, {forceFloat32: true }), 2, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface ClientReady {
}

export function readClientReady(raw: ArrayBuffer): ClientReady {
  return decode(readMessagePayload(raw)) as ClientReady;
}

export function createClientReady({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: ClientReady;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  return createMessage(senderId, 65549, encode(instance, {forceFloat32: true }), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface ConnectToken {
  ServerSessionId: string;
  ContextType: ContextType;
  UserType: UserType;
  PayloadType: PayloadType;
  IsInternal: boolean;
  Description: string;
  UserId: string;
  DeviceId: string;
  ProductId: string;
  VersionId: string;
  InstallId: string;
  Locale: string;
  OpcodeGroupsFromServer: Opcode;
  OpcodeGroupsToServer: Opcode;
  ProtocolVersion: number;
  HasInput: boolean;
  ChannelLocale: string;
  EmbeddedSpaceId: string;
}

export interface Context {
  ContextType: ContextType;
  UserType: UserType;
  PayloadType: PayloadType;
  Description: string;
  UserId: string;
  DeviceId: string;
  ProductId: string;
  VersionId: string;
  InstallId: string;
  Locale: string;
  SessionId: number;
  IsInternal: boolean;
  IsReady: boolean;
  HasInput: boolean;
  ChannelLocale: string;
  EmbeddedSpaceId: string;
}

export interface Coordinate2D {
  X: number;
  Y: number;
}

export interface Entrypoint {
  Type: EntrypointType;
  Uri: string;
  OpcodeGroupsFromServer: Opcode;
  OpcodeGroupsToServer: Opcode;
  Priority: number;
  Description: string;
  AuthTicket: Uint8Array;
}

export interface EventsOnChannelComplete {
}

export function readEventsOnChannelComplete(raw: ArrayBuffer): EventsOnChannelComplete {
  return decode(readMessagePayload(raw)) as EventsOnChannelComplete;
}

export function createEventsOnChannelComplete({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: EventsOnChannelComplete;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  return createMessage(senderId, 262146, encode(instance, {forceFloat32: true }), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface EventsOnProfileUpdate {
  UserId: string;
  ValuesAsJson: string;
}

export function readEventsOnProfileUpdate(raw: ArrayBuffer): EventsOnProfileUpdate {
  return decode(readMessagePayload(raw)) as EventsOnProfileUpdate;
}

export function createEventsOnProfileUpdate({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: EventsOnProfileUpdate;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  return createMessage(senderId, 262145, encode(instance, {forceFloat32: true }), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface FunctionParameter {
  Name: string;
  ValueType: ProtocolValueType;
  HasDefaultValue: boolean;
  DefaultValueJson: string;
  AllowedValues: string[];
}

export interface GlobalState {
  Clients: Record<number, Context>;
  UIStreams: Record<string, UIStreamState>;
  AudioStreams: Record<string, AudioStreamState>;
  VideoStreams: Record<string, VideoStreamState>;
  TrackingStreams: Record<string, TrackingStreamState>;
}

export function readGlobalState(raw: ArrayBuffer): GlobalState {
  return decode(readMessagePayload(raw)) as GlobalState;
}

export function createGlobalState({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: GlobalState;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  return createMessage(senderId, 65539, encode(instance, {forceFloat32: true }), 2, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface InvalidateVideoFrame {
  FrameNumber: number;
  TimeStampInUs: number;
}

export function readInvalidateVideoFrame(raw: ArrayBuffer): InvalidateVideoFrame {
  return decode(readMessagePayload(raw)) as InvalidateVideoFrame;
}

export function createInvalidateVideoFrame({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: InvalidateVideoFrame;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  return createMessage(senderId, 8388613, encode(instance, {forceFloat32: true }), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface KeepaliveRequest {
}

export function readKeepaliveRequest(raw: ArrayBuffer): KeepaliveRequest {
  return decode(readMessagePayload(raw)) as KeepaliveRequest;
}

export function createKeepaliveRequest({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: KeepaliveRequest;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  return createMessage(senderId, 131073, encode(instance, {forceFloat32: true }), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface KeepaliveResponse {
}

export function readKeepaliveResponse(raw: ArrayBuffer): KeepaliveResponse {
  return decode(readMessagePayload(raw)) as KeepaliveResponse;
}

export function createKeepaliveResponse({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: KeepaliveResponse;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  return createMessage(senderId, 131074, encode(instance, {forceFloat32: true }), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface OnClientJoined {
  ClientContext: Context;
}

export function readOnClientJoined(raw: ArrayBuffer): OnClientJoined {
  return decode(readMessagePayload(raw)) as OnClientJoined;
}

export function createOnClientJoined({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: OnClientJoined;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  return createMessage(senderId, 65543, encode(instance, {forceFloat32: true }), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface OnClientLeft {
  ClientContext: Context;
}

export function readOnClientLeft(raw: ArrayBuffer): OnClientLeft {
  return decode(readMessagePayload(raw)) as OnClientLeft;
}

export function createOnClientLeft({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: OnClientLeft;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  return createMessage(senderId, 65544, encode(instance, {forceFloat32: true }), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface OnClientReady {
  ClientContext: Context;
}

export function readOnClientReady(raw: ArrayBuffer): OnClientReady {
  return decode(readMessagePayload(raw)) as OnClientReady;
}

export function createOnClientReady({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: OnClientReady;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  return createMessage(senderId, 65548, encode(instance, {forceFloat32: true }), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface OnHostedServerExit {
  ServerSessionId: string;
  WasSuccessful: boolean;
}

export function readOnHostedServerExit(raw: ArrayBuffer): OnHostedServerExit {
  return decode(readMessagePayload(raw)) as OnHostedServerExit;
}

export function createOnHostedServerExit({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: OnHostedServerExit;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  return createMessage(senderId, 65554, encode(instance, {forceFloat32: true }), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface OnPluginReloaded {
  ServerContext: Context;
  PluginName: string;
}

export function readOnPluginReloaded(raw: ArrayBuffer): OnPluginReloaded {
  return decode(readMessagePayload(raw)) as OnPluginReloaded;
}

export function createOnPluginReloaded({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: OnPluginReloaded;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  return createMessage(senderId, 65551, encode(instance, {forceFloat32: true }), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface OnServerStarted {
  ServerContext: Context;
}

export function readOnServerStarted(raw: ArrayBuffer): OnServerStarted {
  return decode(readMessagePayload(raw)) as OnServerStarted;
}

export function createOnServerStarted({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: OnServerStarted;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  return createMessage(senderId, 65545, encode(instance, {forceFloat32: true }), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface OnServerStatusPing {
  ServerContext: Context;
  Status: ServerStatus;
  UserCount: number;
  ClientCount: number;
  HumanClientCount: number;
  IdleTimeInSeconds: number;
  SentMessagesPerSecond: number;
  SentMessagesBandwidth: number;
  SentMessagesCount: number;
  ReceivedMessagesPerSecond: number;
  ReceivedMessagesBandwidth: number;
  ReceivedMessagesCount: number;
  ProcessCpuUsage: number;
  ProcessMemoryUsedMb: number;
  ManagedMemoryUsedMb: number;
}

export function readOnServerStatusPing(raw: ArrayBuffer): OnServerStatusPing {
  return decode(readMessagePayload(raw)) as OnServerStatusPing;
}

export function createOnServerStatusPing({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: OnServerStatusPing;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  return createMessage(senderId, 65540, encode(instance, {forceFloat32: true }), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface OnServerStopped {
  ServerContext: Context;
}

export function readOnServerStopped(raw: ArrayBuffer): OnServerStopped {
  return decode(readMessagePayload(raw)) as OnServerStopped;
}

export function createOnServerStopped({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: OnServerStopped;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  return createMessage(senderId, 65546, encode(instance, {forceFloat32: true }), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface OnServerStopping {
  ServerContext: Context;
}

export function readOnServerStopping(raw: ArrayBuffer): OnServerStopping {
  return decode(readMessagePayload(raw)) as OnServerStopping;
}

export function createOnServerStopping({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: OnServerStopping;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  return createMessage(senderId, 65547, encode(instance, {forceFloat32: true }), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface OnUserJoined {
  ClientContext: Context;
}

export function readOnUserJoined(raw: ArrayBuffer): OnUserJoined {
  return decode(readMessagePayload(raw)) as OnUserJoined;
}

export function createOnUserJoined({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: OnUserJoined;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  return createMessage(senderId, 65541, encode(instance, {forceFloat32: true }), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface OnUserLeft {
  ClientContext: Context;
}

export function readOnUserLeft(raw: ArrayBuffer): OnUserLeft {
  return decode(readMessagePayload(raw)) as OnUserLeft;
}

export function createOnUserLeft({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: OnUserLeft;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  return createMessage(senderId, 65542, encode(instance, {forceFloat32: true }), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface ProtocolValueType {
  ValueType: FunctionValueType;
  KeyType: FunctionKeyType;
  IsArray: boolean;
  IsNullable: boolean;
}

export interface RequestIdrVideoFrame {
}

export function readRequestIdrVideoFrame(raw: ArrayBuffer): RequestIdrVideoFrame {
  return decode(readMessagePayload(raw)) as RequestIdrVideoFrame;
}

export function createRequestIdrVideoFrame({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: RequestIdrVideoFrame;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  return createMessage(senderId, 8388612, encode(instance, {forceFloat32: true }), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface SceneArray {
  SerializerType: number;
  Type: string;
  SubId: string;
  ElementOffset: number;
  ElementCount: number;
  ByteOffset: number;
  TypeSize: number;
  StrideSize: number;
  ByteArray: Uint8Array;
}

export function readSceneArray(raw: ArrayBuffer): SceneArray {
  const instanceAsArray = decode(readMessagePayload(raw)) as any[];

  const instance: SceneArray = {
    SerializerType: instanceAsArray[0],
    Type: instanceAsArray[1],
    SubId: instanceAsArray[2],
    ElementOffset: instanceAsArray[3],
    ElementCount: instanceAsArray[4],
    ByteOffset: instanceAsArray[5],
    TypeSize: instanceAsArray[6],
    StrideSize: instanceAsArray[7],
    ByteArray: instanceAsArray[8],
  };

  return instance;
}

export function createSceneArray({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: SceneArray;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  const instanceAsArray: any[] = [
    instance.SerializerType,
    instance.Type,
    instance.SubId,
    instance.ElementOffset,
    instance.ElementCount,
    instance.ByteOffset,
    instance.TypeSize,
    instance.StrideSize,
    instance.ByteArray,
  ];

  return createMessage(senderId, 33554434, encode(instanceAsArray, {forceFloat32: true }), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface SceneMesh {
  Vertices: number[];
}

export function readSceneMesh(raw: ArrayBuffer): SceneMesh {
  const instanceAsArray = decode(readMessagePayload(raw)) as any[];

  const instance: SceneMesh = {
    Vertices: instanceAsArray[0],
  };

  return instance;
}

export function createSceneMesh({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: SceneMesh;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  const instanceAsArray: any[] = [
    instance.Vertices,
  ];

  return createMessage(senderId, 33554433, encode(instanceAsArray, {forceFloat32: true }), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface ServerInit {
  IkonBackendUrl: string;
  IkonBackendToken: string;
  SpaceId: string;
  RoomId: string;
  Plugins: ServerPluginInit[];
}

export function readServerInit(raw: ArrayBuffer): ServerInit {
  return decode(readMessagePayload(raw)) as ServerInit;
}

export function createServerInit({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: ServerInit;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  return createMessage(senderId, 65550, encode(instance, {forceFloat32: true }), 5, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface ServerPluginInit {
  Enabled: boolean;
  TypeName: string;
  ConfigFilePath: string;
  ConfigJsonContent: string;
  ExtraConfigs: ServerPluginInitExtraConfig[];
}

export interface ServerPluginInitExtraConfig {
  FilePath: string;
  Content: string;
}

export interface ServerStart {
  HostServerSessionId: string;
  ConfigJsonContent: string;
}

export function readServerStart(raw: ArrayBuffer): ServerStart {
  return decode(readMessagePayload(raw)) as ServerStart;
}

export function createServerStart({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: ServerStart;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  return createMessage(senderId, 65552, encode(instance, {forceFloat32: true }), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface ServerStop {
  HostServerSessionId: string;
  TargetServerSessionId: string;
}

export function readServerStop(raw: ArrayBuffer): ServerStop {
  return decode(readMessagePayload(raw)) as ServerStop;
}

export function createServerStop({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: ServerStop;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  return createMessage(senderId, 65553, encode(instance, {forceFloat32: true }), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface TrackingFrame {
  TimestampInUs: number;
  DurationInUs: number;
  FaceBlendshapes: number[];
  FaceTransformationMatrix: number[];
}

export function readTrackingFrame(raw: ArrayBuffer): TrackingFrame {
  const instanceAsArray = decode(readMessagePayload(raw)) as any[];

  const instance: TrackingFrame = {
    TimestampInUs: instanceAsArray[0],
    DurationInUs: instanceAsArray[1],
    FaceBlendshapes: instanceAsArray[2],
    FaceTransformationMatrix: instanceAsArray[3],
  };

  return instance;
}

export function createTrackingFrame({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: TrackingFrame;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  const instanceAsArray: any[] = [
    instance.TimestampInUs,
    instance.DurationInUs,
    instance.FaceBlendshapes,
    instance.FaceTransformationMatrix,
  ];

  return createMessage(senderId, 16777219, encode(instanceAsArray, {forceFloat32: true }), 2, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface TrackingStreamBegin {
  Category: string;
  Type: TrackingType;
  FaceBlendshapes: string[];
}

export function readTrackingStreamBegin(raw: ArrayBuffer): TrackingStreamBegin {
  return decode(readMessagePayload(raw)) as TrackingStreamBegin;
}

export function createTrackingStreamBegin({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: TrackingStreamBegin;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  return createMessage(senderId, 16777217, encode(instance, {forceFloat32: true }), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface TrackingStreamEnd {
}

export function readTrackingStreamEnd(raw: ArrayBuffer): TrackingStreamEnd {
  return decode(readMessagePayload(raw)) as TrackingStreamEnd;
}

export function createTrackingStreamEnd({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: TrackingStreamEnd;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  return createMessage(senderId, 16777218, encode(instance, {forceFloat32: true }), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface TrackingStreamState {
  StreamId: string;
  ClientSessionId: number;
  TrackId: number;
  Info: TrackingStreamBegin;
}

export interface UIBadge {
  Text: string;
  Color: UIColor;
  ClickActionId: string;
  Variant: UIBadgeVariant;
  ElementId: number;
  Labels: string[];
}

export function readUIBadge(raw: ArrayBuffer): UIBadge {
  const instanceAsArray = decode(readMessagePayload(raw)) as any[];

  const instance: UIBadge = {
    Text: instanceAsArray[2],
    Color: instanceAsArray[3],
    ClickActionId: instanceAsArray[4],
    Variant: instanceAsArray[5],
    ElementId: instanceAsArray[0],
    Labels: instanceAsArray[1],
  };

  return instance;
}

export function createUIBadge({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: UIBadge;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  const instanceAsArray: any[] = [
    instance.Text,
    instance.Color,
    instance.ClickActionId,
    instance.Variant,
    instance.ElementId,
    instance.Labels,
  ];

  return createMessage(senderId, 2097169, encode(instanceAsArray, {forceFloat32: true }), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface UIButton {
  Text: string;
  Icon: UIIconType;
  Color: UIColor;
  ClickActionId: string;
  Variant: UIButtonVariant;
  ElementId: number;
  Labels: string[];
}

export function readUIButton(raw: ArrayBuffer): UIButton {
  const instanceAsArray = decode(readMessagePayload(raw)) as any[];

  const instance: UIButton = {
    Text: instanceAsArray[2],
    Icon: instanceAsArray[3],
    Color: instanceAsArray[4],
    ClickActionId: instanceAsArray[5],
    Variant: instanceAsArray[6],
    ElementId: instanceAsArray[0],
    Labels: instanceAsArray[1],
  };

  return instance;
}

export function createUIButton({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: UIButton;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  const instanceAsArray: any[] = [
    instance.Text,
    instance.Icon,
    instance.Color,
    instance.ClickActionId,
    instance.Variant,
    instance.ElementId,
    instance.Labels,
  ];

  return createMessage(senderId, 2097165, encode(instanceAsArray, {forceFloat32: true }), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface UIButtonBegin {
  Color: UIColor;
  ClickActionId: string;
  Variant: UIButtonVariant;
  ElementId: number;
  Labels: string[];
}

export function readUIButtonBegin(raw: ArrayBuffer): UIButtonBegin {
  const instanceAsArray = decode(readMessagePayload(raw)) as any[];

  const instance: UIButtonBegin = {
    Color: instanceAsArray[2],
    ClickActionId: instanceAsArray[3],
    Variant: instanceAsArray[4],
    ElementId: instanceAsArray[0],
    Labels: instanceAsArray[1],
  };

  return instance;
}

export function createUIButtonBegin({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: UIButtonBegin;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  const instanceAsArray: any[] = [
    instance.Color,
    instance.ClickActionId,
    instance.Variant,
    instance.ElementId,
    instance.Labels,
  ];

  return createMessage(senderId, 2097177, encode(instanceAsArray, {forceFloat32: true }), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface UIButtonEnd {
}

export function readUIButtonEnd(raw: ArrayBuffer): UIButtonEnd {
  const instanceAsArray = decode(readMessagePayload(raw)) as any[];

  const instance: UIButtonEnd = {
    
  };

  return instance;
}

export function createUIButtonEnd({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: UIButtonEnd;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  const instanceAsArray: any[] = [
    
  ];

  return createMessage(senderId, 2097178, encode(instanceAsArray, {forceFloat32: true }), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface UIContainerBegin {
  ContainerId: string;
  UserId: string;
  CreatedAt: string;
  UpdatedAt: string;
  AlternativeText: string;
  IsTransient: boolean;
  IsHistory: boolean;
  IsUpdate: boolean;
}

export function readUIContainerBegin(raw: ArrayBuffer): UIContainerBegin {
  const instanceAsArray = decode(readMessagePayload(raw)) as any[];

  const instance: UIContainerBegin = {
    ContainerId: instanceAsArray[0],
    UserId: instanceAsArray[1],
    CreatedAt: instanceAsArray[2],
    UpdatedAt: instanceAsArray[3],
    AlternativeText: instanceAsArray[4],
    IsTransient: instanceAsArray[5],
    IsHistory: instanceAsArray[6],
    IsUpdate: instanceAsArray[7],
  };

  return instance;
}

export function createUIContainerBegin({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: UIContainerBegin;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  const instanceAsArray: any[] = [
    instance.ContainerId,
    instance.UserId,
    instance.CreatedAt,
    instance.UpdatedAt,
    instance.AlternativeText,
    instance.IsTransient,
    instance.IsHistory,
    instance.IsUpdate,
  ];

  return createMessage(senderId, 2097155, encode(instanceAsArray, {forceFloat32: true }), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface UIContainerDelete {
}

export function readUIContainerDelete(raw: ArrayBuffer): UIContainerDelete {
  const instanceAsArray = decode(readMessagePayload(raw)) as any[];

  const instance: UIContainerDelete = {
    
  };

  return instance;
}

export function createUIContainerDelete({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: UIContainerDelete;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  const instanceAsArray: any[] = [
    
  ];

  return createMessage(senderId, 2097179, encode(instanceAsArray, {forceFloat32: true }), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface UIContainerEnd {
}

export function readUIContainerEnd(raw: ArrayBuffer): UIContainerEnd {
  const instanceAsArray = decode(readMessagePayload(raw)) as any[];

  const instance: UIContainerEnd = {
    
  };

  return instance;
}

export function createUIContainerEnd({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: UIContainerEnd;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  const instanceAsArray: any[] = [
    
  ];

  return createMessage(senderId, 2097156, encode(instanceAsArray, {forceFloat32: true }), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface UIContentLink {
  Type: UIContentLinkType;
  Code: string;
  ElementId: number;
  Labels: string[];
}

export function readUIContentLink(raw: ArrayBuffer): UIContentLink {
  const instanceAsArray = decode(readMessagePayload(raw)) as any[];

  const instance: UIContentLink = {
    Type: instanceAsArray[2],
    Code: instanceAsArray[3],
    ElementId: instanceAsArray[0],
    Labels: instanceAsArray[1],
  };

  return instance;
}

export function createUIContentLink({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: UIContentLink;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  const instanceAsArray: any[] = [
    instance.Type,
    instance.Code,
    instance.ElementId,
    instance.Labels,
  ];

  return createMessage(senderId, 2097170, encode(instanceAsArray, {forceFloat32: true }), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface UIFile {
  Name: string;
  SecondaryText: string;
  Type: string;
  Mime: string;
  AllowedMimeTypes: string[];
  MaxSize: number;
  FileInfo: UIFileInfo;
  OpenActionId: string;
  UploadActionId: string;
  RemoveActionId: string;
  ElementId: number;
  Labels: string[];
}

export function readUIFile(raw: ArrayBuffer): UIFile {
  return decode(readMessagePayload(raw)) as UIFile;
}

export function createUIFile({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: UIFile;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  return createMessage(senderId, 2097168, encode(instance, {forceFloat32: true }), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface UIFileInfo {
  Id: string;
  Name: string;
  FileName: string;
  CreatedAt: string;
  Size: number;
  Url: string;
}

export interface UIFileUploadSectionBegin {
  Gap: number;
  UploadActionId: string;
  AllowedMimeTypes: string[];
  MaxSize: number;
}

export function readUIFileUploadSectionBegin(raw: ArrayBuffer): UIFileUploadSectionBegin {
  const instanceAsArray = decode(readMessagePayload(raw)) as any[];

  const instance: UIFileUploadSectionBegin = {
    Gap: instanceAsArray[0],
    UploadActionId: instanceAsArray[1],
    AllowedMimeTypes: instanceAsArray[2],
    MaxSize: instanceAsArray[3],
  };

  return instance;
}

export function createUIFileUploadSectionBegin({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: UIFileUploadSectionBegin;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  const instanceAsArray: any[] = [
    instance.Gap,
    instance.UploadActionId,
    instance.AllowedMimeTypes,
    instance.MaxSize,
  ];

  return createMessage(senderId, 2097174, encode(instanceAsArray, {forceFloat32: true }), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface UIFileUploadSectionEnd {
}

export function readUIFileUploadSectionEnd(raw: ArrayBuffer): UIFileUploadSectionEnd {
  const instanceAsArray = decode(readMessagePayload(raw)) as any[];

  const instance: UIFileUploadSectionEnd = {
    
  };

  return instance;
}

export function createUIFileUploadSectionEnd({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: UIFileUploadSectionEnd;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  const instanceAsArray: any[] = [
    
  ];

  return createMessage(senderId, 2097175, encode(instanceAsArray, {forceFloat32: true }), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface UIHeader {
  Text: string;
  SubText: string;
  Level: UIHeaderLevel;
  ElementId: number;
  Labels: string[];
}

export function readUIHeader(raw: ArrayBuffer): UIHeader {
  const instanceAsArray = decode(readMessagePayload(raw)) as any[];

  const instance: UIHeader = {
    Text: instanceAsArray[2],
    SubText: instanceAsArray[3],
    Level: instanceAsArray[4],
    ElementId: instanceAsArray[0],
    Labels: instanceAsArray[1],
  };

  return instance;
}

export function createUIHeader({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: UIHeader;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  const instanceAsArray: any[] = [
    instance.Text,
    instance.SubText,
    instance.Level,
    instance.ElementId,
    instance.Labels,
  ];

  return createMessage(senderId, 2097163, encode(instanceAsArray, {forceFloat32: true }), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface UIIcon {
  Icon: UIIconType;
  Color: UIColor;
  ElementId: number;
  Labels: string[];
}

export function readUIIcon(raw: ArrayBuffer): UIIcon {
  const instanceAsArray = decode(readMessagePayload(raw)) as any[];

  const instance: UIIcon = {
    Icon: instanceAsArray[2],
    Color: instanceAsArray[3],
    ElementId: instanceAsArray[0],
    Labels: instanceAsArray[1],
  };

  return instance;
}

export function createUIIcon({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: UIIcon;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  const instanceAsArray: any[] = [
    instance.Icon,
    instance.Color,
    instance.ElementId,
    instance.Labels,
  ];

  return createMessage(senderId, 2097173, encode(instanceAsArray, {forceFloat32: true }), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface UIIconButton {
  Icon: UIIconType;
  Color: UIColor;
  ClickActionId: string;
  ElementId: number;
  Labels: string[];
}

export function readUIIconButton(raw: ArrayBuffer): UIIconButton {
  const instanceAsArray = decode(readMessagePayload(raw)) as any[];

  const instance: UIIconButton = {
    Icon: instanceAsArray[2],
    Color: instanceAsArray[3],
    ClickActionId: instanceAsArray[4],
    ElementId: instanceAsArray[0],
    Labels: instanceAsArray[1],
  };

  return instance;
}

export function createUIIconButton({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: UIIconButton;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  const instanceAsArray: any[] = [
    instance.Icon,
    instance.Color,
    instance.ClickActionId,
    instance.ElementId,
    instance.Labels,
  ];

  return createMessage(senderId, 2097166, encode(instanceAsArray, {forceFloat32: true }), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface UIImage {
  Name: string;
  Url: string;
  Mime: string;
  Data: Uint8Array;
  ClickActionId: string;
  ElementId: number;
  Labels: string[];
}

export function readUIImage(raw: ArrayBuffer): UIImage {
  const instanceAsArray = decode(readMessagePayload(raw)) as any[];

  const instance: UIImage = {
    Name: instanceAsArray[2],
    Url: instanceAsArray[3],
    Mime: instanceAsArray[4],
    Data: instanceAsArray[5],
    ClickActionId: instanceAsArray[6],
    ElementId: instanceAsArray[0],
    Labels: instanceAsArray[1],
  };

  return instance;
}

export function createUIImage({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: UIImage;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  const instanceAsArray: any[] = [
    instance.Name,
    instance.Url,
    instance.Mime,
    instance.Data,
    instance.ClickActionId,
    instance.ElementId,
    instance.Labels,
  ];

  return createMessage(senderId, 2097167, encode(instanceAsArray, {forceFloat32: true }), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface UIInputText {
  Name: string;
  UpdateActionId: string;
  Color: UIColor;
  Variant: UIInputVariant;
  ElementId: number;
  Labels: string[];
}

export function readUIInputText(raw: ArrayBuffer): UIInputText {
  const instanceAsArray = decode(readMessagePayload(raw)) as any[];

  const instance: UIInputText = {
    Name: instanceAsArray[2],
    UpdateActionId: instanceAsArray[3],
    Color: instanceAsArray[4],
    Variant: instanceAsArray[5],
    ElementId: instanceAsArray[0],
    Labels: instanceAsArray[1],
  };

  return instance;
}

export function createUIInputText({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: UIInputText;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  const instanceAsArray: any[] = [
    instance.Name,
    instance.UpdateActionId,
    instance.Color,
    instance.Variant,
    instance.ElementId,
    instance.Labels,
  ];

  return createMessage(senderId, 2097180, encode(instanceAsArray, {forceFloat32: true }), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface UIListBegin {
  Type: UIListType;
}

export function readUIListBegin(raw: ArrayBuffer): UIListBegin {
  const instanceAsArray = decode(readMessagePayload(raw)) as any[];

  const instance: UIListBegin = {
    Type: instanceAsArray[0],
  };

  return instance;
}

export function createUIListBegin({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: UIListBegin;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  const instanceAsArray: any[] = [
    instance.Type,
  ];

  return createMessage(senderId, 2097159, encode(instanceAsArray, {forceFloat32: true }), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface UIListEnd {
}

export function readUIListEnd(raw: ArrayBuffer): UIListEnd {
  const instanceAsArray = decode(readMessagePayload(raw)) as any[];

  const instance: UIListEnd = {
    
  };

  return instance;
}

export function createUIListEnd({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: UIListEnd;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  const instanceAsArray: any[] = [
    
  ];

  return createMessage(senderId, 2097161, encode(instanceAsArray, {forceFloat32: true }), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface UIListItem {
  Name: string;
  Text: string;
  ElementId: number;
  Labels: string[];
}

export function readUIListItem(raw: ArrayBuffer): UIListItem {
  const instanceAsArray = decode(readMessagePayload(raw)) as any[];

  const instance: UIListItem = {
    Name: instanceAsArray[2],
    Text: instanceAsArray[3],
    ElementId: instanceAsArray[0],
    Labels: instanceAsArray[1],
  };

  return instance;
}

export function createUIListItem({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: UIListItem;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  const instanceAsArray: any[] = [
    instance.Name,
    instance.Text,
    instance.ElementId,
    instance.Labels,
  ];

  return createMessage(senderId, 2097160, encode(instanceAsArray, {forceFloat32: true }), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface UIMap {
  Name: string;
  Marker: UIMapMarker;
  Markers: UIMapMarker[];
  ElementId: number;
  Labels: string[];
}

export function readUIMap(raw: ArrayBuffer): UIMap {
  return decode(readMessagePayload(raw)) as UIMap;
}

export function createUIMap({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: UIMap;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  return createMessage(senderId, 2097171, encode(instance, {forceFloat32: true }), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface UIMapMarker {
  Title: string;
  Latitude: number;
  Longitude: number;
}

export interface UIMaterialSymbol {
  Name: string;
  Color: UIColor;
  ElementId: number;
  Labels: string[];
}

export function readUIMaterialSymbol(raw: ArrayBuffer): UIMaterialSymbol {
  const instanceAsArray = decode(readMessagePayload(raw)) as any[];

  const instance: UIMaterialSymbol = {
    Name: instanceAsArray[2],
    Color: instanceAsArray[3],
    ElementId: instanceAsArray[0],
    Labels: instanceAsArray[1],
  };

  return instance;
}

export function createUIMaterialSymbol({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: UIMaterialSymbol;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  const instanceAsArray: any[] = [
    instance.Name,
    instance.Color,
    instance.ElementId,
    instance.Labels,
  ];

  return createMessage(senderId, 2097176, encode(instanceAsArray, {forceFloat32: true }), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface UISectionBegin {
  Type: UISectionType;
  Gap: number;
  ClickActionId: string;
  Labels: string[];
}

export function readUISectionBegin(raw: ArrayBuffer): UISectionBegin {
  const instanceAsArray = decode(readMessagePayload(raw)) as any[];

  const instance: UISectionBegin = {
    Type: instanceAsArray[0],
    Gap: instanceAsArray[1],
    ClickActionId: instanceAsArray[2],
    Labels: instanceAsArray[3],
  };

  return instance;
}

export function createUISectionBegin({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: UISectionBegin;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  const instanceAsArray: any[] = [
    instance.Type,
    instance.Gap,
    instance.ClickActionId,
    instance.Labels,
  ];

  return createMessage(senderId, 2097157, encode(instanceAsArray, {forceFloat32: true }), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface UISectionEnd {
}

export function readUISectionEnd(raw: ArrayBuffer): UISectionEnd {
  const instanceAsArray = decode(readMessagePayload(raw)) as any[];

  const instance: UISectionEnd = {
    
  };

  return instance;
}

export function createUISectionEnd({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: UISectionEnd;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  const instanceAsArray: any[] = [
    
  ];

  return createMessage(senderId, 2097158, encode(instanceAsArray, {forceFloat32: true }), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface UISeparator {
  ElementId: number;
  Labels: string[];
}

export function readUISeparator(raw: ArrayBuffer): UISeparator {
  const instanceAsArray = decode(readMessagePayload(raw)) as any[];

  const instance: UISeparator = {
    ElementId: instanceAsArray[0],
    Labels: instanceAsArray[1],
  };

  return instance;
}

export function createUISeparator({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: UISeparator;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  const instanceAsArray: any[] = [
    instance.ElementId,
    instance.Labels,
  ];

  return createMessage(senderId, 2097164, encode(instanceAsArray, {forceFloat32: true }), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface UIStreamBegin {
  Category: string;
}

export function readUIStreamBegin(raw: ArrayBuffer): UIStreamBegin {
  return decode(readMessagePayload(raw)) as UIStreamBegin;
}

export function createUIStreamBegin({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: UIStreamBegin;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  return createMessage(senderId, 2097153, encode(instance, {forceFloat32: true }), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface UIStreamEnd {
}

export function readUIStreamEnd(raw: ArrayBuffer): UIStreamEnd {
  return decode(readMessagePayload(raw)) as UIStreamEnd;
}

export function createUIStreamEnd({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: UIStreamEnd;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  return createMessage(senderId, 2097154, encode(instance, {forceFloat32: true }), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface UIStreamState {
  StreamId: string;
  ClientSessionId: number;
  TrackId: number;
  Info: UIStreamBegin;
}

export interface UIText {
  Text: string;
  Type: UITextType;
  ElementId: number;
  Labels: string[];
}

export function readUIText(raw: ArrayBuffer): UIText {
  const instanceAsArray = decode(readMessagePayload(raw)) as any[];

  const instance: UIText = {
    Text: instanceAsArray[2],
    Type: instanceAsArray[3],
    ElementId: instanceAsArray[0],
    Labels: instanceAsArray[1],
  };

  return instance;
}

export function createUIText({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: UIText;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  const instanceAsArray: any[] = [
    instance.Text,
    instance.Type,
    instance.ElementId,
    instance.Labels,
  ];

  return createMessage(senderId, 2097162, encode(instanceAsArray, {forceFloat32: true }), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface UIVegaChart {
  DataJson: string;
  SpecJson: string;
  ElementId: number;
  Labels: string[];
}

export function readUIVegaChart(raw: ArrayBuffer): UIVegaChart {
  const instanceAsArray = decode(readMessagePayload(raw)) as any[];

  const instance: UIVegaChart = {
    DataJson: instanceAsArray[2],
    SpecJson: instanceAsArray[3],
    ElementId: instanceAsArray[0],
    Labels: instanceAsArray[1],
  };

  return instance;
}

export function createUIVegaChart({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: UIVegaChart;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  const instanceAsArray: any[] = [
    instance.DataJson,
    instance.SpecJson,
    instance.ElementId,
    instance.Labels,
  ];

  return createMessage(senderId, 2097172, encode(instanceAsArray, {forceFloat32: true }), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface VideoFrame {
  Data: Uint8Array;
  FrameNumber: number;
  IsKey: boolean;
  TimestampInUs: number;
  DurationInUs: number;
}

export function readVideoFrame(raw: ArrayBuffer): VideoFrame {
  const instanceAsArray = decode(readMessagePayload(raw)) as any[];

  const instance: VideoFrame = {
    Data: instanceAsArray[0],
    FrameNumber: instanceAsArray[1],
    IsKey: instanceAsArray[2],
    TimestampInUs: instanceAsArray[3],
    DurationInUs: instanceAsArray[4],
  };

  return instance;
}

export function createVideoFrame({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: VideoFrame;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  const instanceAsArray: any[] = [
    instance.Data,
    instance.FrameNumber,
    instance.IsKey,
    instance.TimestampInUs,
    instance.DurationInUs,
  ];

  return createMessage(senderId, 8388611, encode(instanceAsArray, {forceFloat32: true }), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface VideoStreamBegin {
  Description: string;
  Codec: VideoCodec;
  Width: number;
  Height: number;
}

export function readVideoStreamBegin(raw: ArrayBuffer): VideoStreamBegin {
  return decode(readMessagePayload(raw)) as VideoStreamBegin;
}

export function createVideoStreamBegin({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: VideoStreamBegin;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  return createMessage(senderId, 8388609, encode(instance, {forceFloat32: true }), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface VideoStreamEnd {
}

export function readVideoStreamEnd(raw: ArrayBuffer): VideoStreamEnd {
  return decode(readMessagePayload(raw)) as VideoStreamEnd;
}

export function createVideoStreamEnd({
  instance,
  senderId,
  trackId = 0,
  sequenceId = 0,
  flags = 0,
  targetIds = [],
}: {
  instance: VideoStreamEnd;
  senderId: number;
  trackId?: number;
  sequenceId?: number;
  flags?: number;
  targetIds?: number[];
}): Uint8Array {
  return createMessage(senderId, 8388610, encode(instance, {forceFloat32: true }), 1, PayloadType.MessagePack, trackId, sequenceId, flags, targetIds);
}

export interface VideoStreamState {
  StreamId: string;
  ClientSessionId: number;
  TrackId: number;
  Info: VideoStreamBegin;
}

