import { UserRole } from '@ikon-web/user-types';
import { Box, Button, CircularProgress, Divider, Link, Typography } from '@mui/material';
import Stack from '@mui/material/Stack';
import { useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import runsOnIkonMind from '../../assets/runs-on-ikon-mind.svg';
import { PrivacyPolicyModal } from '../../components/privacy-policy-modal';
import { TermsOfServiceModal } from '../../components/terms-of-service-modal';
import { LOCAL_STORAGE_AUTH_REDIRECT } from '../../shared/constants';
import { useAuth } from '../../shared/hooks/use-auth';
import { useSpace } from '../../shared/hooks/use-space';
import { domain } from '../../shared/utils/domain.utils';
import { featureDirectLogin, featureTestAccounts } from '../../shared/utils/env.utils';
import { iframeMessages } from '../../shared/utils/iframe.utils';
import { EmailLogin } from './email-login';

export function LoginPage() {
  const { loginProvider, loginUsername, isLoading, user } = useAuth();
  const location = useLocation();
  const space = useSpace();

  const [privacyPolicyOpen, setPrivacyPolicyOpen] = useState(false);
  const [termsOfServiceOpen, setTermsOfServiceOpen] = useState(false);

  useEffect(() => {
    if (isLoading) return;
    if (user && user.role !== UserRole.Anonymous) return;

    iframeMessages({ type: 'live' });
  }, [isLoading, user]);

  function saveRedirect() {
    if (location.state?.redirect) {
      console.debug(`[Meet] Redirect: ${location.state.redirect}`);
      localStorage.setItem(LOCAL_STORAGE_AUTH_REDIRECT, location.state.redirect);
    }
  }

  function loginWithProvider(provider: string) {
    saveRedirect();
    loginProvider(provider);
  }

  function loginWithTestUser(index: number) {
    saveRedirect();
    loginUsername(`user${index}`, 'password');
  }

  if (isLoading) {
    return (
      <Box sx={{ maxWidth: '320px', ml: 'auto', mr: 'auto' }}>
        <Box component="img" src={runsOnIkonMind} sx={{ height: '14px' }} />

        <Typography variant="h1" sx={{ mt: 7, fontSize: '24px', fontWeight: '400', letterSpacing: '0.18px' }}>
          Log in to <strong>{space?.customisation?.name ?? 'Ikon'}</strong>
        </Typography>

        <Typography variant="body1" sx={{ mt: 6, textAlign: 'center' }}>
          <CircularProgress />
        </Typography>
      </Box>
    );
  }

  if (user && user.role !== UserRole.Anonymous) {
    const redirect = localStorage.getItem(LOCAL_STORAGE_AUTH_REDIRECT);
    if (redirect) localStorage.removeItem(LOCAL_STORAGE_AUTH_REDIRECT);
    return <Navigate to={redirect || '/rooms'} />;
  }

  if (featureDirectLogin) {
    loginWithProvider('google');

    return (
      <Box sx={{ maxWidth: '320px', ml: 'auto', mr: 'auto' }}>
        <Box component="img" src={runsOnIkonMind} sx={{ height: '14px' }} />

        <Typography variant="h1" sx={{ mt: 7, fontSize: '24px', fontWeight: '400', letterSpacing: '0.18px' }}>
          Log in to <strong>{space?.customisation?.name ?? 'Ikon'}</strong>
        </Typography>

        <Typography variant="body1" sx={{ mt: 6, fontSize: '16px', color: 'rgba(255, 255, 255, 0.87)' }}>
          Logging in...
        </Typography>
      </Box>
    );
  }

  return (
    <Stack direction="column" useFlexGap gap="64px" sx={{ maxWidth: '320px', alignItems: 'flex-start', height: '100%' }}>
      <Box component="img" src={runsOnIkonMind} sx={{ height: '14px', display: { xs: 'none', md: 'block' } }} />
      <Stack direction="column" useFlexGap gap="48px" sx={{ alignItems: 'flex-start', height: '100%' }}>
        <Typography variant="h1" sx={{ fontSize: '24px', fontWeight: 400, letterSpacing: '0.18px', m: 0, p: 0 }}>
          {space.invitation?.invitationRequired ? 'Log in' : 'Create an account or log in'} to {space?.customisation?.name ?? 'Ikon'}
        </Typography>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            flex: '1 0 0',
            alignSelf: 'stretch',
          }}
        >
          <Stack direction="column" useFlexGap gap={{ xs: '16px', md: '24px' }} sx={{ flexGrow: 1 }}>
            <EmailLogin onRequestLogin={() => saveRedirect()} />
            {/*TODO: Add to space configs*/}
            {domain !== 'finestfuture' && (
              <>
                <Divider />
                <Stack spacing={2} alignItems="center">
                  <Button variant="outlined" fullWidth onClick={() => loginWithProvider('google')} sx={{ borderRadius: '14px' }}>
                    Continue with Google
                  </Button>
                </Stack>
              </>
            )}
            {featureTestAccounts && (
              <>
                <Divider sx={{ my: 4 }}>TEST ACCOUNTS</Divider>

                <Stack spacing={2} alignItems="center">
                  <Button variant="outlined" fullWidth onClick={() => loginWithTestUser(1)}>
                    Test User 1
                  </Button>
                  <Button variant="outlined" fullWidth onClick={() => loginWithTestUser(2)}>
                    Test User 2
                  </Button>
                  <Button variant="outlined" fullWidth onClick={() => loginWithTestUser(3)}>
                    Test User 3
                  </Button>
                  <Button variant="outlined" fullWidth onClick={() => loginWithTestUser(4)}>
                    Test User 4
                  </Button>
                </Stack>
              </>
            )}
            <Divider />
            <Typography
              variant="body1"
              sx={{
                fontSize: '12px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: '16px',
                letterSpacing: '0.4px',
              }}
            >
              Please review our{' '}
              <Link onClick={() => setTermsOfServiceOpen(true)} sx={{ color: '#9999EA', cursor: 'pointer' }}>
                Terms of Service
              </Link>{' '}
              and{' '}
              <Link onClick={() => setPrivacyPolicyOpen(true)} sx={{ color: '#9999EA', cursor: 'pointer' }}>
                Privacy Policy
              </Link>
              , detailing platform rules and data handling. By using the service, you agree to these terms.
            </Typography>

            <Typography>
              <Link href="mailto:support@ikon.live" sx={{ fontSize: '12px', fontWeight: 400, textDecoration: 'none', color: 'text.primary' }}>
                support@ikon.live
              </Link>
            </Typography>
          </Stack>
          <Box sx={{ display: { xs: 'none', md: 'flex' }, justifyContent: 'center', alignSelf: 'stretch' }}>
            <Link href="mailto:support@ikon.live" sx={{ fontSize: '12px', fontWeight: '300', textDecoration: 'none', color: 'text.primary' }}>
              support@ikon.live
            </Link>
          </Box>

          <TermsOfServiceModal open={termsOfServiceOpen} close={() => setTermsOfServiceOpen(false)} />
          <PrivacyPolicyModal open={privacyPolicyOpen} close={() => setPrivacyPolicyOpen(false)} />
        </Box>
      </Stack>

      <Box sx={{ justifyContent: 'center', alignSelf: 'stretch', display: { xs: 'flex', md: 'none' } }}>
        <Box component="img" src={runsOnIkonMind} sx={{ height: '14px' }} />
      </Box>
    </Stack>
  );
}
