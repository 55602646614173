import { Box, CircularProgress, Link, Typography } from '@mui/material';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import { useSpace } from '../../shared/hooks/use-space';
import { useConsumeInvitation } from './use-consume-invitation';

export function InvitationPage() {
  const { code } = useParams();
  const navigate = useNavigate();
  const { state } = useConsumeInvitation(code!);
  const space = useSpace();

  if (state === 'ok') {
    navigate('/rooms');
    return;
  }

  if (state === 'error') {
    return (
      <Box sx={{ p: 2, maxWidth: '512px', ml: 'auto', mr: 'auto', textAlign: 'center' }}>
        <Typography variant="h1" mt={5} sx={{ fontSize: '48px' }}>
          <strong>{space?.customisation?.name ?? 'Ikon'}</strong>
        </Typography>

        <Typography variant="h5" component="h2" mt={2} sx={{ fontSize: '16px', color: '#9196FF' }}>
          Powered by IkonAI
        </Typography>

        <Typography variant="body1" sx={{ mt: 5, mb: 2, fontSize: '16px' }}>
          Invitation not found or expired.
        </Typography>
        <Typography variant="body1" sx={{ mt: 5, mb: 2, fontSize: '16px' }}>
          <Link component={RouterLink} to="/auth/login" sx={{ textDecoration: 'none' }}>
            Go back to login
          </Link>
        </Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ p: 2, maxWidth: '512px', ml: 'auto', mr: 'auto', textAlign: 'center' }}>
      <Typography variant="h1" mt={5} sx={{ fontSize: '48px' }}>
        <strong>{space?.customisation?.name ?? 'Ikon'}</strong>
      </Typography>

      <Typography variant="h5" component="h2" mt={2} sx={{ fontSize: '16px', color: '#9196FF' }}>
        Powered by IkonAI
      </Typography>

      <Typography variant="body1" sx={{ mt: 5, mb: 2, fontSize: '16px' }}>
        Processing invitation...
      </Typography>
      <Box sx={{ textAlign: 'center' }}>
        <CircularProgress />
      </Box>
    </Box>
  );
}
